import { CalculationError } from '../calculator';
import { ReportStatement, getReportStatement } from '../calculator.util.report';
import { ElementPrice, ElementRateSource } from '@core/types/types.elements';

export function calculate_selectPrice(elemRate: ElementPrice): {
  rate: number;
  source: ElementRateSource;
  report: ReportStatement[];
} {
  let rate: number | undefined = undefined;
  let source: ElementRateSource | undefined = undefined;

  const fmcts = elemRate.fmc_price_timestamp || '';
  const pp1ts = elemRate.planned_price_1_timestamp || '';
  const pp2ts = elemRate.planned_price_2_timestamp || '';
  const pp3ts = elemRate.planned_price_3_timestamp || '';

  if (typeof elemRate.planned_price_1 === 'number' && pp1ts > fmcts) {
    rate = elemRate.planned_price_1;
    source = ElementRateSource.GSIM;
  } else if (typeof elemRate.fmc_price === 'number') {
    rate = elemRate.fmc_price;
    source = ElementRateSource.FMC;
  } else if (typeof elemRate.planned_price_2 === 'number' && pp2ts >= pp3ts) {
    rate = elemRate.planned_price_2;
    source = ElementRateSource.MAN;
  } else if (typeof elemRate.planned_price_3 === 'number') {
    rate = elemRate.planned_price_3;
    source = ElementRateSource.MEDIUM;
  }

  if (rate === undefined || !source) {
    throw new CalculationError({ message: 'Unable to find a rate' });
  }

  return {
    rate,
    source,
    report: [
      getReportStatement({
        region: 'EU',
        description: `Source: ${source}`,
        value: rate,
      }),
      getReportStatement({
        region: 'US',
        description: `Source: ${source}`,
        value: rate,
      }),
    ],
  };
}
